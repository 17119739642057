import React from "react"
import "../css/Home.css"



function Home() {
    return (
        <>
           <div className="background">
                <header className = "header-title">The Cocktail Connoisseur App</header>
                <h3 className = "header-description">Created By: Zak Ward</h3>
            </div>
         </> 
    )
}

export default Home